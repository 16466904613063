// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #008cba;
  --ion-color-primary-rgb: 0, 140, 186;
  --ion-color-primary-shade: #018cba;
  --ion-color-primary-tint: #1ca5d9;
  --ion-color-primary-darker: hsl(from var(--ion-color-primary) h s calc(l - 50));
  --ion-color-primary-lighter: hsl(from var(--ion-color-primary) h s calc(l + 50));

  /** secondary **/
  --ion-color-secondary: #e51990;
  --ion-color-secondary-rgb: 0, 140, 186;
  --ion-color-secondary-shade: #980f5f;
  --ion-color-secondary-tint: #d31b30;
}
