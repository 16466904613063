address {
  font-style: normal;
}
.container {
  margin: 0 auto;
  padding-bottom: 10rem;
  min-height: 44.5vh;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@font-face {
  font-family: 'Open Sans';
  src:
    local('Open Sans'),
    url(../assets/font/OpenSans/OpenSans-Regular.ttf) format('truetype');
}

:root {
  --ion-font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  h1 {
    margin-bottom: 0.5rem;
    font-family: var(--ion-font-family);
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
    padding-bottom: 10px;

    &.entry-title {
      font-size: 1.31rem;
      border-bottom: 1px solid #eaecef;
    }
  }

  .icon-only .loading-wrapper {
    box-shadow: none;
    background: transparent;

    ion-spinner {
      color: var(--ion-color-primary-contrast);
      width: 100px !important;
      height: 100px !important;
    }
  }

  @media (max-width: 576px) {
    ion-alert.alert-wide .alert-wrapper {
      min-width: 90% !important;
    }
    ion-popover.alert-wide::part(content) {
      min-width: 90% !important;
    }
  }

  @media (min-width: 576px) {
    ion-alert.alert-wide .alert-wrapper {
      min-width: calc(576px * 0.9) !important;
    }
    ion-popover.alert-wide::part(content) {
      min-width: calc(576px * 0.9) !important;
    }
  }

  .dotted {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

ion-toast.dark {
  --background: var(--ion-color-dark);
  --color: var(--ion-color-light);
}

ion-app {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

ion-modal.fullscreen {
  --height: 100%;
  --width: 100%;

  ion-datetime {
    width: 100% !important;
    height: 100% !important;
  }
}
