/* rich text editor styles */

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: end;
}

.quill iframe {
  display: block;
  width: 100% !important;
  aspect-ratio: 16 / 9;
  max-width: 688px;
  margin: 0 auto;
}
